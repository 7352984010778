
.background {
    background-image: url('../images/Wild-Background-Image.png');
    min-height: 100vh;
    background-size: cover;
    display: flex;
    flex-direction: column;
  }

  .logo {
    width: 16%; /* Default width */
}

@media only screen and (max-width: 768px) {
    .logo {
        width: 25%; /* Adjusted width for iPhones */
    }
}

.homeText {
  font-family: 'Yeseva One';
  font-weight: 400;
  font-size: 4.0rem;
}

@media only screen and (max-width: 768px) {
  .homeText {
    font-size: 2.8rem;
  }
}

.servicesCard {
  max-width: 315px;
  min-width: 315px;
}

@media only screen and (max-width: 768px) {
  .servicesCard {
    max-width: 150px;
    min-width: 150px;
  }
}

.cardMedia {
  height: 115px;
}

@media only screen and (max-width: 768px) {
  .cardMedia {
    height: 50px;
  }
}

.homeParentBox {
  display: flex;
  width: 100%;
  padding-top: 7%;
  min-height: 65vh;
}

@media only screen and (max-width: 768px) {
  .homeParentBox {
    display: flex;
    width: 100%;
    padding-top: 1%;
    min-height: 65vh;
  }
}