
.nav-list {
    list-style: none;
    display: flex;
}

.nav-list li {
    margin-right: 75px; 
}

.nav-link {
    text-decoration: none; /* Remove underline */
    color: black; 
}

.logo {
    width: 16%; /* Default width */
}

@media only screen and (max-width: 768px) {
    .logo {
        width: 25%; /* Adjusted width for iPhones */
    }
}