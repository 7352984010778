.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

:root {
  font-size: 16px;
}

body {
  font-size: 1rem;
}

h1 {
  font-size: 2rem;
}

p {
  font-size: 1rem;
}

@media (max-width: 600px) {
  :root {
    font-size: 14px;
  }

  h1 {
    font-size: 1.5rem;
  }

  p {
    font-size: 0.85rem;
  }
}